<template>
  <default-layout>
    <v-container fluid tag="section">
      <page-loading :show="loading || loadingForm" />
      <v-alert type="error" :value="showAlert" dismissible>
        <div v-html="messageAlert" />
      </v-alert>
      <agent-detail-form
        :detail="detail"
        :permissions="permissions"
        :allowedActions="allowedActions"
        style="width: 100%"
      ></agent-detail-form>
      <note-list :items="notes ? notes : []" :permissions="permissions"></note-list>
    </v-container>
    <modal-agent></modal-agent>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const AgentDetailForm = () =>
  import('@/components/user-management/beliruma-user/agent-detail-form');
const NoteList = () => import('@/components/user-management/beliruma-user/note-list');
const ModalAgent = () => import('@/components/modals/modal-agent');
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    PageLoading,
    AgentDetailForm,
    NoteList,
    ModalAgent,
  },
  data() {
    return {
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.belirumaUser.loading,
      loadingForm: (state) => state.belirumaUser.form.loading,
      permissions: (state) => state.belirumaUser.permissions,
      allowedActions: (state) => state.belirumaUser.allowedActions,
      detail: (state) => state.belirumaUser.detail,
      listings: (state) => state.listing.listings,
      notes: (state) => state.belirumaUser.form.notes,
    }),
  },
  async mounted() {
    await this.refreshAction();
  },
  methods: {
    async refreshAction() {
      await this.loadDetail();
      // await this.changeListingPage(1);
    },
    async loadDetail() {
      try {
        this.$store.commit('belirumaUser/RESET_DEFAULT');
        this.$store.commit('belirumaUser/form/SET_FORM_TYPE', 'EDIT');
        const response = await this.$store.dispatch(
          'belirumaUser/getDetailInitData',
          this.$route.params.uuid,
        );
        console.log('DETAIL AGEN: ', response);
      } catch (e) {
        this.showAlert = true;
        this.messageAlert = e;
      }
    },
    // async changeListingPage(page) {
    //   if (this.detail && this.detail.membership) {
    //     try {
    //       this.isLoading = true;
    //       let query = this.$route.query;
    //       query.user_uuid = this.$route.params.uuid;
    //       query.page = page;
    //       const response = await this.$store.dispatch('listing/getInitData', query);
    //       console.log('GET LISTING DATA: ', response);
    //     } catch (e) {
    //       this.showAlert = true;
    //       this.messageAlert = e;
    //     } finally {
    //       this.isLoading = false;
    //     }
    //   }
    // },
  },
};
</script>
